import {
  Box,
  Button,
  Flex,
  Text,
  Title,
  createStyles,
  rem,
} from "@mantine/core";
import { useGetUser } from "api";
import MainLayout from "components/layouts/MainLayout";
import type {
  WalletCardsCreateRequestData,
  WalletCardsCreateResponseData,
} from "features/wallets/types";
import type { FC } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import type { WalletCardForm } from "types/form";

const useStyles = createStyles(() => ({
  box: {
    borderBottom: "1px solid #e0e0e0",
    paddingBottom: rem(8),
    paddingTop: rem(8),
  },
  label: {
    fontSize: rem(12),
    fontWeight: 700,
    marginBottom: rem(8),
  },
  text: {
    color: "#191919",
    fontSize: rem(14),
  },
}));

type CreateTrigger = (
  data: WalletCardsCreateRequestData,
  options?: {
    onError: ({ errors }: { errors: string[] }) => void;
    onSuccess: () => void;
    throwOnError: boolean;
  }
) => Promise<WalletCardsCreateResponseData>;

type NewConfirmProps = {
  errorPath: string;
  isMutating: boolean;
  registerButtonPath: string;
  trigger: CreateTrigger;
};

const NewConfirm: FC<NewConfirmProps> = ({
  errorPath,
  registerButtonPath,
  trigger,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { classes } = useStyles();
  const { mutate } = useGetUser();

  // stateが正しく取得出来ていない場合は、入力画面に戻す
  if (!location.state) return <Navigate to={errorPath} />;

  const { card_number, pin_code } = location.state as WalletCardForm;

  const postData = { card_number, pin_code };

  const onClickButton = () => {
    void trigger(postData, {
      onError: ({ errors }) => {
        navigate({
          pathname: errorPath,
          search: `?errors=${errors.join("_")}`,
        }); // 入力画面に戻す
      },
      onSuccess: () => {
        // カード登録状態を最新にする
        void mutate().then(() => {
          navigate(location.pathname, { replace: true }); // state を削除
          navigate(registerButtonPath); // 完了画面へ
        });
      },
      throwOnError: false,
    });
  };

  return (
    <MainLayout footer={false}>
      <Flex
        direction="column"
        justify="space-between"
        style={{ height: "100%" }}
      >
        <Box>
          <Title ta="center" size={18} fw="700" mb={20} color="#333">
            入力内容を確認してください
          </Title>
          <Box mb={8} className={classes.box}>
            <Text className={classes.label}>カード番号</Text>
            <Text className={classes.text}>{card_number}</Text>
          </Box>

          <Box mb={8} className={classes.box}>
            <Text className={classes.label}>PIN番号</Text>
            <Text className={classes.text}>{pin_code}</Text>
          </Box>
        </Box>

        <Box>
          <Button
            size="lg"
            radius={5}
            fz={16}
            fw="700"
            mb={16}
            fullWidth
            onClick={onClickButton}
          >
            登録する
          </Button>

          <Button
            variant="outline"
            color="gray"
            size="lg"
            radius={5}
            fz={16}
            fw="700"
            fullWidth
            onClick={() =>
              navigate(`/wallet/new`, { state: { card_number, pin_code } })
            }
          >
            修正する
          </Button>
        </Box>
      </Flex>
    </MainLayout>
  );
};

export default NewConfirm;
