import {
  Box,
  Button,
  Checkbox,
  Flex,
  Text,
  Title,
  createStyles,
  rem,
} from "@mantine/core";
import { useGetUser } from "api";
import MainLayout from "components/layouts/MainLayout";
import Loading from "components/modules/Loading";
import { useWalletCardsShow } from "features/wallets/api";
import type {
  WalletCardsDestroyRequestData,
  WalletCardsDestroyResponseData,
} from "features/wallets/types";
import { PAGE_TITLE } from "libs/constants";
import type { FC } from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const useStyles = createStyles(() => ({
  box: {
    paddingBottom: rem(8),
    paddingTop: rem(8),
  },
  label: {
    fontSize: rem(12),
    fontWeight: 700,
    marginBottom: rem(8),
  },
  text: {
    color: "#191919",
    fontSize: rem(14),
  },
}));

type DestroyTrigger = (
  data: WalletCardsDestroyRequestData,
  options?: {
    onError: ({ errors }: { errors: string[] }) => void;
    onSuccess: () => void;
    throwOnError: boolean;
  }
) => Promise<WalletCardsDestroyResponseData>;

type ConfirmProps = {
  errorPath: string;
  id: string;
  isMutating: boolean;
  registerButtonPath: string;
  trigger: DestroyTrigger;
};

const DestroyConfirm: FC<ConfirmProps> = ({
  id,
  errorPath,
  registerButtonPath,
  trigger,
}) => {
  const { data: card } = useWalletCardsShow(id);
  const [isChecked, setIsChecked] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const { classes } = useStyles();
  const { mutate } = useGetUser();

  if (!card) return <Loading pageTitle={PAGE_TITLE.ICHIYAMA_MART} />;

  const submit = () => {
    void trigger(
      { id: id },
      {
        onError: ({ errors }) => {
          navigate({
            pathname: errorPath,
            search: `?errors=${errors.join("_")}`,
          }); // 入力画面に戻す
        },
        onSuccess: () => {
          // カード登録状態を最新にする
          void mutate().then(() => {
            navigate(location.pathname, { replace: true }); // state を削除
            navigate(registerButtonPath); // 完了画面へ
          });
        },
        throwOnError: false,
      }
    );
  };

  return (
    <MainLayout footer={false} pageTitle={PAGE_TITLE.CARD_DESTROY}>
      <Flex
        direction="column"
        justify="space-between"
        style={{ height: "100%" }}
      >
        <Box>
          <Title size={18} fw="700" mb={20} color="#333">
            カードの登録を解除しますか？
          </Title>

          <Text mb={16}>
            登録解除後も、残高は引き続きご利用いただけます。解除前にカード番号とPIN番号をお控えください。
          </Text>

          <Box mb={8} className={classes.box}>
            <Text className={classes.label}>カード番号</Text>
            <Text className={classes.text}>{card.card_number}</Text>
          </Box>

          <Box mb={8} className={classes.box}>
            <Text className={classes.label}>PIN番号</Text>
            <Text className={classes.text}>{card.pin_code}</Text>
          </Box>

          <Box mb={8} className={classes.box}>
            <Text className={classes.label}>イッツモアカード残高</Text>
            <Text className={classes.text}>
              {card.balance.toLocaleString()}円
            </Text>
          </Box>

          <Box mb={8} className={classes.box}>
            <Text className={classes.label}>有効期限</Text>
            <Text className={classes.text}>{card.expire_at}</Text>
          </Box>
        </Box>

        <Box>
          <Checkbox
            mt={32}
            mb={16}
            label="カード番号とPIN番号を確認しました"
            checked={isChecked}
            onChange={(event) => setIsChecked(event.currentTarget.checked)}
          />
          <Button
            size="lg"
            radius={5}
            fz={16}
            fw="700"
            mb={16}
            fullWidth
            onClick={submit}
            disabled={!isChecked}
          >
            登録を解除する
          </Button>

          <Button
            variant="outline"
            color="gray"
            size="lg"
            radius={5}
            fz={16}
            fw="700"
            fullWidth
            onClick={() => navigate(`/wallet/cards/${id}`)}
          >
            キャンセル
          </Button>
        </Box>
      </Flex>
    </MainLayout>
  );
};

export default DestroyConfirm;
