import { useWalletCardsCreate } from "features/wallets/api";
import { default as NewConfirmComponent } from "features/wallets/components/cards/NewConfirm";
import type { FC } from "react";

const NewConfirm: FC = () => {
  const { trigger, isMutating } = useWalletCardsCreate();

  return (
    <NewConfirmComponent
      errorPath="/wallet/new"
      isMutating={isMutating}
      registerButtonPath="/wallet/complete"
      trigger={trigger}
    />
  );
};

export default NewConfirm;
