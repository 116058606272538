// eslint-disable-next-line import/no-named-as-default
import liff from "@line/liff";
import { Button, Flex, Image, Stack, Tabs, Text, rem } from "@mantine/core";
import { useGetMonthlyPurchaseHistories, useGetPointBalance } from "api";
import { useWalletCardsIndex } from "features/wallets/api";
import { useParams } from "react-router-dom";

import { ReactComponent as ChevronRightIcon } from "assets/images/icons/chevron_right.svg";
import itsmore_card from "assets/images/itsmore_card.jpg";
import more_card from "assets/images/more_card.png";
import MainLayout from "components/layouts/MainLayout";
import Loading from "components/modules/Loading";
import dayjs from "dayjs";
import UserBarcodeCard from "features/user/components/UserBarcodeCard";
import UserPointBalanceCard from "features/user/components/UserPointBalanceCard";
/* wallets */
import { default as UserWalletCard } from "features/wallets/components/cards/Card";
import { PAGE_TITLE } from "libs/constants";
import { useMemo, type FC } from "react";

const Home: FC = () => {
  const { data, isValidating, mutate } = useGetPointBalance();
  const { data: walletData } = useWalletCardsIndex();
  let { activeTab } = useParams<{ activeTab?: string }>();

  const tabWallet = "wallet";
  const tabMembership = "membership";
  // activeTabが"wallet"以外の場合は、defaultTabを"membership"に設定
  if (activeTab !== tabWallet) {
    activeTab = tabMembership;
  }

  // 自動テストだと「更新」ボタン クリック時、API006 月ごとの購入履歴 が2回呼び出されてしまうので、メモ化する
  // https://github.com/voyagegroup/digiclue-ichiyamamart-front/pull/63
  const currentMonth = useMemo(() => dayjs().format("YYYY-MM"), []);
  const {
    data: monthlyPurchaseHistoriesData,
    isValidating: isValidatingMonthlyPurchaseHistories,
    mutate: mutateMonthlyPurchaseHistories,
  } = useGetMonthlyPurchaseHistories({
    endMonth: currentMonth,
    startMonth: currentMonth,
  });

  const onMutate = async () => {
    await mutate();
    await mutateMonthlyPurchaseHistories();
  };

  if (
    isValidating ||
    !data ||
    isValidatingMonthlyPurchaseHistories ||
    !monthlyPurchaseHistoriesData ||
    !walletData
  )
    return <Loading pageTitle={PAGE_TITLE.ICHIYAMA_MART} />;

  const { card_number, ...rest } = data;
  const { cards } = walletData;

  return (
    <MainLayout header footer pageTitle={PAGE_TITLE.ICHIYAMA_MART}>
      {/* 会員証・ウォレットカード切り替えナビゲーション */}
      <Tabs defaultValue={activeTab} mt="-20px">
        <Tabs.List
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Tabs.Tab
            value={tabMembership}
            style={{
              flex: 1,
              textAlign: "center",
            }}
          >
            <Flex align="center">
              <Image
                src={more_card}
                alt="モアカード"
                style={{ width: "40%" }}
              />
              <Text style={{ width: "60%", paddingLeft: "8px" }}>モアカード</Text>
            </Flex>
          </Tabs.Tab>
          <Tabs.Tab
            value={tabWallet}
            style={{
              flex: 1,
              textAlign: "center",
            }}
          >
            <Flex align="center">
              <Image
                src={itsmore_card}
                alt="イッツモアカード"
                style={{ width: "40%" }}
              />
              <Text style={{ width: "60%", paddingLeft: "8px" }}>イッツモアカード</Text>
            </Flex>
          </Tabs.Tab>
        </Tabs.List>

        {/*** 会員証 「モアカード」 ***/}
        <Tabs.Panel value={tabMembership} pt="xs">
          <Stack spacing={24}>
            {/* 会員バーコードカード */}
            <UserBarcodeCard card_number={card_number} />
            {/* ポイント残高（取得済クーポン・保有ポイント・サービス券）及びお買上げ金額表示カード */}
            <UserPointBalanceCard
              {...rest}
              onMutate={onMutate}
              purchase_amount={
                monthlyPurchaseHistoriesData.purchase_histories[0]
                  .purchase_amount
              }
            />
            {/* 「クーポン券対象商品」ボタン */}
            <Button
              variant="outline"
              size="lg"
              rightIcon={<ChevronRightIcon />}
              fw={700}
              sx={{ borderWidth: rem(2) }}
              styles={{ rightIcon: { marginLeft: rem(4) } }}
              onClick={() =>
                liff.openWindow({
                  external: false,
                  url: "https://ichiyamamart.com/bimimagazine/coupon-point",
                })
              }
            >
              クーポン券対象商品
            </Button>
            {/* 「サービス券カタログ」ボタン */}
            <Button
              variant="outline"
              size="lg"
              rightIcon={<ChevronRightIcon />}
              fw={700}
              sx={{ borderWidth: rem(2) }}
              styles={{ rightIcon: { marginLeft: rem(4) } }}
              onClick={() =>
                liff.openWindow({
                  external: false,
                  url: "https://ichiyamamart.com/bimimagazine/30yen-sale-change",
                })
              }
            >
              サービス券カタログ
            </Button>
          </Stack>
        </Tabs.Panel>

        {/*** ウォレット 「イッツモアカード」 ***/}
        <Tabs.Panel value={tabWallet} pt="xs">
          <Stack spacing={24}>
            {/*** いちやまマート案件での「イッツモアカード」は1枚までしか登録できない仕様 ***/}
            <UserWalletCard card={cards[0]} />
          </Stack>
        </Tabs.Panel>
      </Tabs>
    </MainLayout>
  );
};

export default Home;
