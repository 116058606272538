import { AppShell, Container } from "@mantine/core";
import Footer from "components/modules/Footer";
import Header from "components/modules/Header";
import { PAGE_TITLE } from "libs/constants";
import type { FC, ReactNode } from "react";
import { Helmet } from "react-helmet-async";

type MainLayoutProps = {
  children?: ReactNode;
  footer?: boolean;
  header?: boolean;
  pageTitle?: string;
};

const MainLayout: FC<MainLayoutProps> = ({
  header = true,
  footer = true,
  pageTitle,
  children,
}) => {
  return (
    <>
      <Helmet>
        <title>{pageTitle ?? PAGE_TITLE.ICHIYAMA_MART}</title>
      </Helmet>
      {/* heightを設定しないとscrollでない時もあるため、強制的に設定する */}
      <AppShell
        padding={0}
        sx={{
          main: {
            height: "100dvh", // こちらのdvhの設定が優先ですが、dvhの設定が効かない場合、vhのheightが効きます。dvhのがさらに汎用的になったらこちらを削除しstylesのheightをdvhに変更します。
            minHeight: "100dvh",
          },
        }}
        h="100vh"
        styles={{
          main: {
            height: "100vh",
            overflowY: "scroll",
          },
        }}
        header={header ? <Header /> : undefined}
        footer={footer ? <Footer /> : undefined}
      >
        <Container p="md" h="100%">
          {children}
        </Container>
      </AppShell>
    </>
  );
};

export default MainLayout;
