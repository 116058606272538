import { Button, Flex, Paper, Stack, Text, createStyles } from "@mantine/core";
import { ReactComponent as HistoryIcon } from "assets/images/icons/history.svg";
import { ReactComponent as InformationIcon } from "assets/images/icons/information.svg";
import { ReactComponent as ReloadIcon } from "assets/images/icons/reload.svg";
import type { FC } from "react";
import { useNavigate } from "react-router-dom";

type BalanceProps = {
  balance?: number;
  expire_at?: string;
  id?: string;
};

const primaryColor = "#00AC97";
const disabledColor = "#ACACAC";
const getColor = (id: string | undefined) =>
  id === undefined ? disabledColor : primaryColor;

const useStyles = createStyles(() => ({
  button: {
    width: "44px",
    height: "44px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

/** ウォレットカード残高 */
const CardBalance: FC<BalanceProps> = ({ balance, expire_at, id }) => {
  const navigate = useNavigate();
  const { classes } = useStyles();

  return (
    <Paper radius="md" p="md" sx={{ backgroundColor: "#F5F5F5" }}>
      <Stack>
        {/* 残高表示 */}
        <Flex justify="end" align="center" gap={16}>
          <Flex direction="column" style={{ width: "100%" }}>
            <Text fw="bold">イッツモアカード残高</Text>
            <Text fz={32} fw="bold">
              {balance?.toLocaleString() || "-"}
              <Text component="span" fz="md">
                円
              </Text>
            </Text>
            <Text fz="sm" c="dimmed">
              有効期限 {expire_at || "----/--/--"}
            </Text>
          </Flex>

          <Flex justify="flex-end" style={{ width: "100%" }}>
            {/* 更新ボタン */}
            <Flex direction="column" align="center" mr="8px">
              <Button
                disabled={id === undefined}
                variant="outline"
                radius="xl"
                size="md"
                onClick={() => window.location.reload()}
                className={classes.button}
                style={{ borderColor: getColor(id) }}
              >
                <ReloadIcon style={{ fill: getColor(id) }} />
              </Button>
              <Text fz={14} style={{ color: getColor(id) }}>
                更新
              </Text>
            </Flex>

            {/* 履歴ボタン */}
            <Flex direction="column" align="center" mr="8px">
              <Button
                disabled={id === undefined}
                variant="outline"
                radius="xl"
                size="md"
                onClick={
                  id !== undefined
                    ? () => navigate(`/wallet/cards/${id}/histories`)
                    : undefined
                }
                className={classes.button}
                style={{ borderColor: getColor(id) }}
              >
                <HistoryIcon style={{ fill: getColor(id) }} />
              </Button>

              <Text fz={14} style={{ color: getColor(id) }}>
                履歴
              </Text>
            </Flex>

            {/* 詳細情報ボタン */}
            <Flex direction="column" align="center">
              <Button
                disabled={id === undefined}
                variant="outline"
                radius="xl"
                size="md"
                onClick={
                  id !== undefined
                    ? () => navigate(`/wallet/cards/${id}`)
                    : undefined
                }
                className={classes.button}
                style={{ borderColor: getColor(id) }}
              >
                <InformationIcon style={{ fill: getColor(id) }} />
              </Button>
              <Text fz={14} style={{ color: getColor(id) }}>
                情報
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Stack>
    </Paper>
  );
};

export default CardBalance;
