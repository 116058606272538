import { Box, Button, Center, Flex, Title } from "@mantine/core";
import { ReactComponent as CompleteIcon } from "assets/images/icons/complete.svg";
import MainLayout from "components/layouts/MainLayout";
import { PAGE_TITLE } from "libs/constants";
import type { FC } from "react";
import { useNavigate } from "react-router-dom";

const DestroyComplete: FC = () => {
  const navigate = useNavigate();

  return (
    <MainLayout footer={false} pageTitle={PAGE_TITLE.CARD_DESTROY}>
      <Flex
        direction="column"
        justify="space-between"
        style={{ height: "100%" }}
      >
        <Box>
          <Title size={18} fw="700" ta="center" mb={36} color="#333">
            イッツモアカードの登録解除が完了しました!
          </Title>
          <Center>
            <CompleteIcon />
          </Center>
        </Box>

        <Box>
          <Button
            size="lg"
            fz={16}
            fw="700"
            radius={5}
            fullWidth
            onClick={() => navigate("/")}
          >
            ホームへ
          </Button>
        </Box>
      </Flex>
    </MainLayout>
  );
};

export default DestroyComplete;
