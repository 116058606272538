import DestroyComplete from "./cards/DestroyComplete";
import DestroyConfirm from "./cards/DestroyConfirm";
import New from "./cards/New";
import NewComplete from "./cards/NewComplete";
import NewConfirm from "./cards/NewConfirm";
import Show from "./cards/Show";
import List from "./histories/List";

const Wallet = {
  Card: {
    New,
    Show,
    NewConfirm,
    NewComplete,
    DestroyConfirm,
    DestroyComplete,
  },
  History: {
    List,
  },
};

export default Wallet;
