import {
  Anchor,
  Box,
  Button,
  Center,
  Checkbox,
  Flex,
  Image,
  Text,
  Title,
  createStyles,
  rem,
} from "@mantine/core";
import { matches, useForm } from "@mantine/form";
import { ReactComponent as WarningIcon } from "assets/images/icons/warning.svg";
import itsmore_card_back from "assets/images/itsmore_card_back.png";
import MainLayout from "components/layouts/MainLayout";
import CustomTextInput from "components/modules/CustomTextInput";
import type { FC } from "react";
import { useState } from "react";
import {
  useLocation,
  useNavigate,
  useSearchParams,
  type To,
} from "react-router-dom";
import type { WalletCardForm } from "types/form";

const useStyles = createStyles(() => ({
  text: {
    color: "#9e9e9e",
    fontSize: rem(12),
  },
}));

type NewProps = {
  confirmationButtonPath: To; // 「次へ」ボタン クリック時の遷移先
};

const New: FC<NewProps> = ({ confirmationButtonPath }) => {
  const { classes } = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const postCardResponseErrorsStr = searchParams.get("errors");
  const postCardResponseErrors = postCardResponseErrorsStr?.split("_");
  const [isChecked, setIsChecked] = useState(false);

  const initialValues = (location.state as WalletCardForm) || {
    card_number: "",
    pin_code: "",
  };

  const form = useForm<WalletCardForm>({
    initialValues,
    validate: {
      card_number: matches(/^\d{16}$/, "16桁のカード番号をご入力ください。"),
      pin_code: matches(/^\d+$/, "数字でPIN番号をご入力ください。"),
    },
    validateInputOnBlur: ["card_number", "pin_code"],
  });

  return (
    <MainLayout footer={false}>
      <Title ta="center" size={18} fw="700" mb={20} color="#333">
        カード情報を入力してください
      </Title>

      <Center>
        <Image
          src={itsmore_card_back}
          alt="イッツモアカード（裏面）"
          style={{ width: "80%" }}
        />
      </Center>

      {/* 確認画面 でAPIエラーが発生し、リダイレクトされた際のエラーメッセージ */}
      {postCardResponseErrors && (
        <Flex
          mb={24}
          p={8}
          align="center"
          gap={8}
          sx={{
            background: "rgba(234, 91, 94, 0.1)",
            borderLeft: "2px solid #ea5b5e",
          }}
        >
          <WarningIcon width={16} height={16} />
          <Box>
            {postCardResponseErrors.map((error, key) => (
              <Text key={key} fz="md" c="#ea5b5e">
                {error}
              </Text>
            ))}
          </Box>
        </Flex>
      )}

      <form
        onSubmit={form.onSubmit((values) =>
          navigate(confirmationButtonPath, {
            state: values,
          })
        )}
      >
        <CustomTextInput
          label="カード番号"
          placeholder="例：1234567890123456"
          mb={8}
          {...form.getInputProps("card_number")}
        />
        <Box mb={20}>
          <Text className={classes.text}>
            ※イッツモアカード裏面左下に記載されている16桁の番号をハイフンなしでご入力ください。
          </Text>
        </Box>

        <CustomTextInput
          label="PIN番号"
          placeholder="例：999999"
          mb={8}
          {...form.getInputProps("pin_code")}
        />
        <Box mb={20}>
          <Text className={classes.text}>
            ※イッツモアカード裏面右下に記載されている6桁の番号をハイフンなしでご入力ください。
          </Text>
        </Box>

        <Checkbox
          mt={32}
          mb={16}
          label={
            <Text>
              <Anchor
                href="https://static1.squarespace.com/static/62f9aaa33a6fe8320f52f5aa/t/63b289264f067a6e3a60dfb1/1672644909260/itsMore_TermsOfService.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                規約
              </Anchor>
              を確認して同意します。
            </Text>
          }
          checked={isChecked}
          onChange={(event) => setIsChecked(event.currentTarget.checked)}
        />
        <Button
          type="submit"
          size="lg"
          radius={5}
          fz={16}
          fw="700"
          fullWidth
          disabled={!form.isValid() || !isChecked}
          sx={{
            "&[data-disabled]": { background: "#e0e0e0", color: "#bdbdbd" },
          }}
        >
          次へ
        </Button>
      </form>
    </MainLayout>
  );
};

export default New;
