import { default as ListComponent } from "features/wallets/components/histories/List";
import type { FC } from "react";
import { useParams } from "react-router-dom";

const List: FC = () => {
  const { id } = useParams<{ id: string }>();

  if (!id) return <></>;

  return <ListComponent id={id} />;
};

export default List;
