import {
  Box,
  Button,
  Flex,
  Text,
  Title,
  createStyles,
  rem,
} from "@mantine/core";
import MainLayout from "components/layouts/MainLayout";
import Loading from "components/modules/Loading";
import { useWalletCardsShow } from "features/wallets/api";
import { PAGE_TITLE } from "libs/constants";
import type { FC } from "react";
import { useNavigate } from "react-router-dom";

const useStyles = createStyles(() => ({
  box: {
    paddingBottom: rem(8),
    paddingTop: rem(8),
  },
  label: {
    fontSize: rem(12),
    fontWeight: 700,
    marginBottom: rem(8),
  },
  text: {
    color: "#191919",
    fontSize: rem(14),
  },
}));

type ShowProps = {
  id: string;
};

const Show: FC<ShowProps> = ({ id }) => {
  const { data: card } = useWalletCardsShow(id);

  const { classes } = useStyles();
  const navigate = useNavigate();

  if (!card) return <Loading pageTitle={PAGE_TITLE.ICHIYAMA_MART} />;

  return (
    <MainLayout footer={false} pageTitle={PAGE_TITLE.CARD_INFORMATION}>
      <Flex
        direction="column"
        justify="space-between"
        style={{ height: "100%" }}
      >
        <Box>
          <Title size={18} fw="700" mb={20} color="#333">
            イッツモアカード
          </Title>

          <Box mb={8} className={classes.box}>
            <Text className={classes.label}>カード番号</Text>
            <Text className={classes.text}>{card.card_number}</Text>
          </Box>

          <Box mb={8} className={classes.box}>
            <Text className={classes.label}>PIN番号</Text>
            <Text className={classes.text}>{card.pin_code}</Text>
          </Box>

          <Box mb={8} className={classes.box}>
            <Text className={classes.label}>イッツモアカード残高</Text>
            <Text className={classes.text}>
              {card.balance.toLocaleString()}円
            </Text>
          </Box>

          <Box mb={8} className={classes.box}>
            <Text className={classes.label}>有効期限</Text>
            <Text className={classes.text}>{card.expire_at}</Text>
          </Box>
        </Box>

        <Box>
          <Button
            size="lg"
            radius={5}
            fz={16}
            fw="700"
            mb={16}
            fullWidth
            onClick={() => navigate(`/wallet/cards/${id}/destroy/confirm`)}
          >
            登録を解除する
          </Button>

          <Button
            variant="outline"
            color="gray"
            size="lg"
            radius={5}
            fz={16}
            fw="700"
            fullWidth
            onClick={() => navigate(`/wallet`)}
          >
            戻る
          </Button>
        </Box>
      </Flex>
    </MainLayout>
  );
};

export default Show;
