import {
  Accordion,
  Box,
  Flex,
  Text,
  Title,
  createStyles,
  rem,
} from "@mantine/core";
import MainLayout from "components/layouts/MainLayout";
import Loading from "components/modules/Loading";
import {
  useWalletCardsHistory,
  useWalletCardsShow,
} from "features/wallets/api";
import { PAGE_TITLE } from "libs/constants";
import type { FC } from "react";

const useStyles = createStyles(() => ({
  balance_minus: {
    color: "#DB3C23",
  },
  balance_plus: {
    color: "#006DEB",
  },
  box: {
    paddingBottom: rem(8),
    paddingTop: rem(8),
  },
  label: {
    color: "#646464",
    fontSize: rem(12),
    fontWeight: 700,
  },
  text: {
    color: "#191919",
    fontSize: rem(14),
  },
}));

type ListProps = {
  id: string;
};

const List: FC<ListProps> = ({ id }) => {
  const { classes } = useStyles();
  const { data: cardData } = useWalletCardsShow(id);
  const { data: historyData } = useWalletCardsHistory(id);
  const balance_breakdown = cardData?.balance_breakdown || [];
  const histories = historyData?.histories || [];

  if (!histories || !cardData || !balance_breakdown)
    return <Loading pageTitle={PAGE_TITLE.ICHIYAMA_MART} />;

  const jsx_histories = histories.map((item) => (
    <Box key={item.name} mb={8} className={classes.box}>
      <Flex justify="space-between" style={{ width: "100%" }}>
        <Box>
          <Text size={16} fw="700">
            {item.name}
          </Text>
          <Text className={classes.label}>{item.date}</Text>
        </Box>
        <Box>
          <Text
            className={
              item.amount >= 0 ? classes.balance_plus : classes.balance_minus
            }
          >
            <Text span size={24} fw="700">
              {item.amount >= 0 ? "+" : ""}
              {item.amount.toLocaleString()}
            </Text>
            <Text span size={14} fw="700">
              円
            </Text>
          </Text>
        </Box>
      </Flex>
    </Box>
  ));

  const jsx_balance_breakdown = balance_breakdown.map((item) => (
    <Flex
      key={item.name}
      justify="space-between"
      style={{
        borderBottom: "1px solid #E3E3E3",
        paddingTop: "8px",
        paddingBottom: "8px",
        width: "100%",
      }}
    >
      <Box>
        <Text size={16}>{item.name}</Text>
      </Box>
      <Box>
        <Text size={16}>{item.balance.toLocaleString()}円</Text>
      </Box>
    </Flex>
  ));

  return (
    <MainLayout footer={false} pageTitle={PAGE_TITLE.ICHIYAMA_MART}>
      <Box>
        <Title size={18} fw="700" mb={20} color="#333">
          イッツモアカード残高ご利用履歴
        </Title>

        <Box>
          <Accordion>
            <Accordion.Item key="key" value="value">
              <Accordion.Control>
                <Box>
                  <Text className={classes.label}>イッツモアカード残高</Text>
                  <Text size={24} fw="700">
                    {cardData.balance.toLocaleString()}円
                  </Text>
                  <Text className={classes.label}>
                    有効期限 {cardData.expire_at}
                  </Text>
                </Box>
              </Accordion.Control>

              <Accordion.Panel>{jsx_balance_breakdown}</Accordion.Panel>
            </Accordion.Item>
          </Accordion>
        </Box>

        {jsx_histories}
      </Box>
    </MainLayout>
  );
};

export default List;
