import { Box, Button, Center, Flex, Image, Text, Title } from "@mantine/core";
import itsmore_card from "assets/images/itsmore_card.jpg";
import MainLayout from "components/layouts/MainLayout";
import type { FC } from "react";
import { useNavigate } from "react-router-dom";

const NewComplete: FC = () => {
  const navigate = useNavigate();

  return (
    <MainLayout footer={false}>
      <Flex
        direction="column"
        justify="space-between"
        style={{ height: "100%" }}
      >
        <Box>
          <Title size={18} fw="700" ta="center" mb={20} color="#333">
            イッツモアカードの登録が完了しました!
          </Title>
          <Center style={{ marginBottom: "16px" }}>
            <Image
              src={itsmore_card}
              alt="イッツモアカード"
              style={{ width: "80%" }}
            />
          </Center>
          <Text style={{ marginBottom: "16px" }}>
            ミニアプリでイッツモアカードのバーコードを表示したり、ポイントの残高確認ができます。
            <br />
            ※最終ご利用日より2年間が有効期限になります。
          </Text>
        </Box>

        <Box>
          <Button
            size="lg"
            fz={16}
            fw="700"
            radius={5}
            fullWidth
            onClick={() => navigate("/wallet")}
          >
            ホームへ
          </Button>
        </Box>
      </Flex>
    </MainLayout>
  );
};

export default NewComplete;
