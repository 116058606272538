// eslint-disable-next-line import/no-named-as-default
import liff from "@line/liff";
import { apiRejectHandler } from "api";
import axios, { isAxiosError } from "axios";
import type {
  WalletCardsCreateRequestData,
  WalletCardsCreateResponseData,
  WalletCardsDestroyResponseData,
  WalletCardsHistoryResponseData,
  WalletCardsIndexResponseData,
  WalletCardsShowResponseData,
} from "features/wallets/types";
import { FEATURES_WALLET_API_ENDPOINT, TENANT_CODE } from "libs/constants";
import type { SWRConfiguration } from "swr";
import useSWR from "swr";
import useSWRMutation from "swr/mutation";
import type { ErrorResponseBody, SuccessResponseBody } from "types/api";

const walletApiInstance = axios.create({
  baseURL: FEATURES_WALLET_API_ENDPOINT,
});

walletApiInstance.interceptors.request.use((config) => {
  config.headers.setContentType("application/json; charset=UTF-8");
  config.headers.set("DIGICLUE-TENANT-CODE", TENANT_CODE);
  config.headers.set("DIGICLUE-TOKEN-ID", liff.getIDToken());
  config.headers.set("DIGICLUE-LINE-ACCESS-TOKEN", liff.getAccessToken());
  return config;
});

walletApiInstance.interceptors.response.use(
  (response) => response,
  apiRejectHandler
);

// ---------- GET ----------
const fetcher = <T = unknown>(path: string) =>
  walletApiInstance
    .get<SuccessResponseBody<T>>(path)
    .then(
      // レスポンスボディ { "status": "succeeded", "data": データの中身 } の "data" 部を返す
      (res) => res.data.data
    )
    .catch((error) =>
      // レスポンスボディ { "status": "failed", "errors": [エラー内容の配列] } 自体を返す
      Promise.reject(
        isAxiosError<ErrorResponseBody>(error)
          ? error.response?.data
          : { errors: [], status: "failed" }
      )
    );

/** ウォレットカード一覧  */
export const useWalletCardsIndex = (options: SWRConfiguration = {}) =>
  useSWR<WalletCardsIndexResponseData, ErrorResponseBody>(
    "cards",
    fetcher<WalletCardsIndexResponseData>,
    options
  );

/** ウォレットカード詳細  */
export const useWalletCardsShow = (
  id: string,
  options: SWRConfiguration = {}
) =>
  useSWR<WalletCardsShowResponseData, ErrorResponseBody>(
    `cards/${id}`,
    fetcher<WalletCardsShowResponseData>,
    options
  );

/** ウォレットカード利用履歴  */
export const useWalletCardsHistory = (
  id: string,
  options: SWRConfiguration = {}
) =>
  useSWR<WalletCardsHistoryResponseData, ErrorResponseBody>(
    `cards/${id}/histories`,
    fetcher<WalletCardsHistoryResponseData>,
    options
  );

// ---------- POST ----------
const fetcherForPost = <T = unknown>(path: string, { arg }: { arg: object }) =>
  walletApiInstance
    .post<SuccessResponseBody<T>>(path, arg)
    .then(
      // レスポンスボディ { "status": "succeeded", "data": データの中身 } の "data" 部を返す
      (res) => res.data.data
    )
    .catch((error) =>
      // レスポンスボディ { "status": "failed", "errors": [エラー内容の配列] } 自体を返す
      Promise.reject(
        isAxiosError<ErrorResponseBody>(error)
          ? error.response?.data
          : { errors: [], status: "failed" }
      )
    );

/** ウォレットカード認証  */
export const useWalletCardsCreate = () =>
  useSWRMutation<
    WalletCardsCreateResponseData,
    ErrorResponseBody,
    string,
    WalletCardsCreateRequestData
  >("cards", fetcherForPost);

// ---------- DELETE ----------
const fetcherForDelete = <T = unknown>(
  path: string,
  { arg: { id } }: { arg: { id: string } }
) =>
  walletApiInstance
    .delete<SuccessResponseBody<T>>(`${path.replace(":id", id)}`)
    .then(
      // レスポンスボディ { "status": "succeeded", "data": データの中身 } の "data" 部を返す
      (res) => res.data.data
    )
    .catch((error) =>
      // レスポンスボディ { "status": "failed", "errors": [エラー内容の配列] } 自体を返す
      Promise.reject(
        isAxiosError<ErrorResponseBody>(error)
          ? error.response?.data
          : { errors: [], status: "failed" }
      )
    );

/** ウォレットカード紐づけ解除  */
export const useWalletCardsDestroy = () =>
  useSWRMutation<
    WalletCardsDestroyResponseData,
    ErrorResponseBody,
    string,
    { id: string }
  >("cards/:id", fetcherForDelete);
