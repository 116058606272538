import { Box, Button, Center, Flex, Image, Stack, Text } from "@mantine/core";
import { ReactComponent as PlusIcon } from "assets/images/icons/plus.svg";
import itsmore_card from "assets/images/itsmore_card.jpg";
import CardBalance from "features/wallets/components/cards/CardBalance";
import type { WalletCard } from "features/wallets/types";
import type { FC } from "react";
import Barcode from "react-barcode";
import { useNavigate } from "react-router-dom";

type CardProps = {
  card: WalletCard | null;
};

type CardBarcodeProps = {
  card_number: string;
};

// ウォレットカードが未登録の場合の、登録ボタン
const CardRegisterButton: FC = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        position: "relative",
      }}
      data-testid="wallet-card"
    >
      {/* 背景画像 */}
      <Image src={itsmore_card} alt="イッツモアカード" />
      {/* バーコード */}
      <Center
        w="90%"
        sx={{
          backgroundColor: "white",
          borderRadius: "8px",
          bottom: "10%",
          left: "5%",
          position: "absolute",
        }}
      >
        <Stack spacing={24} style={{ padding: "8px" }}>
          <Flex
            align="center"
            justify="center"
            direction="row"
            style={{ width: "100%" }}
          >
            <Text style={{ marginRight: "8px", width: "60%" }}>
              お持ちのイッツモアカード登録すると、スマホでご利用いただけます
            </Text>
            <Button onClick={() => navigate("/wallet/new")}>
              <PlusIcon style={{ marginRight: "4px" }} />
              <Text size={14}>登録</Text>
            </Button>
          </Flex>
        </Stack>
      </Center>
    </Box>
  );
};

// ウォレットカードが登録済みの場合の、バーコード
const CardBarcode: FC<CardBarcodeProps> = ({ card_number }) => {
  return (
    <Box
      sx={{
        position: "relative",
      }}
      data-testid="wallet-card"
    >
      {/* 背景画像 */}
      <Image src={itsmore_card} alt="イッツモアカード" />
      {/* バーコード */}
      <Center
        w="90%"
        sx={{
          backgroundColor: "white",
          borderRadius: "8px",
          bottom: "10%",
          left: "5%",
          position: "absolute",
        }}
      >
        <Barcode
          value={card_number}
          format="CODE128"
          height={70}
          font="Noto Sans JP"
          fontSize={14}
        />
      </Center>
    </Box>
  );
};

const Card: FC<CardProps> = ({ card }) => {
  if (!card) {
    return (
      <>
        <CardRegisterButton />
        <CardBalance />
      </>
    );
  }
  return (
    <>
      <CardBarcode card_number={card.card_number} />
      <CardBalance
        balance={card.balance}
        expire_at={card.expire_at}
        id={card.id}
      />
    </>
  );
};

export default Card;
