import { default as ShowComponent } from "features/wallets/components/cards/Show";
import type { FC } from "react";
import { useParams } from "react-router-dom";

const Show: FC = () => {
  const { id } = useParams<{ id: string }>();

  if (!id) return <></>;

  return <ShowComponent id={id} />;
};

export default Show;
