import { useWalletCardsDestroy } from "features/wallets/api";
import { default as DestroyConfirmComponent } from "features/wallets/components/cards/DestroyConfirm";
import type { FC } from "react";
import { useParams } from "react-router-dom";

const DestroyConfirm: FC = () => {
  const { trigger, isMutating } = useWalletCardsDestroy();
  const { id } = useParams<{ id: string }>();

  if (!id) return <></>;

  return (
    <DestroyConfirmComponent
      errorPath="/wallet/cards"
      id={id}
      isMutating={isMutating}
      registerButtonPath="/wallet/destroy/complete"
      trigger={trigger}
    />
  );
};

export default DestroyConfirm;
